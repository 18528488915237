import React from 'react'
import '../../App.css';


function AmmyGeorge() {
    window.scrollTo(0, 0)
    return (
        <div className='container'>
            <h1>Ammy George Website</h1>
            <section>
                <div className="banner-ammygeorge">
                </div>
            </section>

            <div className='text'>
                <p>Website layout concept for a weeding dress store</p>
                <p> For additional information about this project please visit:</p>
                <p><a href="https://www.figma.com/file/FPcK0OzinjnQh0J3lTUH7j/Bridal-Design?node-id=0%3A1" target="_blank">Full layout on Figma</a></p>
            </div>
        </div>
    );
    
}

export default AmmyGeorge
